<template>
  <div id="CBNXml" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">Regulatory</div>
      </div>
    </div>

    <!--  -->
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">XML Reports</div>
          <div class="card-body">
            <div class="form-group mb-3">
              <label for="email">Ticket Ref</label>
              <input
                v-model="ticketRef"
                type="text"
                class="form-control"
                placeholder="H1234-ABC99"
                :class="{ 'is-invalid': $v.ticketRef.$error, 'is-valid': !$v.ticketRef.$invalid }"
              />
              <div class="invalid-feedback">
                <span v-if="!$v.ticketRef.required">Ticket ref is required.</span>
              </div>
            </div>

            <!--  -->
            <button type="submit" class="btn btn-warning" @click="downloadFunction">
              <span v-if="!downloading">Download XML</span>
              <span v-else class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
  </div>
</template>

<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      downloading: false,
      ticketRef: ''
    }
  },

  methods: {
    downloadFunction() {
      console.log('Ticket Ref:::::', this.ticketRef)

      this.$v.$touch()
      if (this.$v.$invalid) {
        this.spinner = false
        return
      }

      this.downloading = true
      axios
        .get(`/reports/cbn-xml?ticketRef=${this.ticketRef}`, {
          params: { ticketRef: this.ticketRef }
        })
        .then(res => {
          let blob = new Blob([res.data], { type: 'application/xml' })
          //console.log(blob)
          //console.log(res.data)
          let url = window.URL.createObjectURL(blob)
          window.open(url, '_blank')
          this.downloading = false
        })
    }
  },

  validations: {
    ticketRef: {
      required
    }
  }
}
</script>

<style scoped></style>
